<template>
  <div
    v-if="account"
    class="profile"
  >
    <div class="text-h4 text-bold q-pb-md">
      {{ $t('title.profileSetting') }}
    </div>

    <div class="row border-bottom">
      <AppTabs>
        <q-route-tab
          v-for="tab in $options.tabs"
          :key="tab.label"
          :ripple="false"
          :name="tab.label"
          :label="$t(tab.label)"
          :to="{ name: tab.route }"
          class="no-hover"
        />
      </AppTabs>
    </div>
    <div class="q-py-lg row">
      <div class="col-grow">
        <router-view/>
      </div>
      <Banner
        :type="ENUMS.BANNER_TYPES.IMAGE"
        :location="ENUMS.BANNER_LOCATIONS.PROFILE"
        class="q-ml-lg col-shrink"
      />
    </div>
  </div>
</template>

<script>
import AppTabs from 'common/components/AppTabs';
import Banner from 'common/components/Banner';
import { mapState } from 'vuex';

export default {
  name: 'ProfileLayout',
  components: { Banner, AppTabs },
  tabs: [
    {
      label: 'label.myData',
      route: ENUMS.ROUTES.PROFILE.DATA,
    },
    {
      label: 'label.accessData',
      route: ENUMS.ROUTES.PROFILE.ACCESS_DATA,
    },
  ],
  computed: {
    ...mapState('app', ['account']),
  },
};
</script>
